import React, { useState, useRef } from 'react';
import Layout from '../components/layout';
import emailjs from '@emailjs/browser';
import Seo from '../components/seo';

const Custom = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [canastas, setCanastas] = useState('');
    const [presupuesto, setPresupuesto] = useState('');
    const [msg, setMsg] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_y8ztxxq', 'template_uobwgde', form.current, 'kmRWxT-JuDgeNRFfC')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        setName('');
        setEmail('');
        setPhone('');
        setMsg('');
        setCanastas('');
        setPresupuesto('');
      };

    return (
        <Layout>
            <div className="w-screen pt-32 flex flex-col inset-x-0 top-0">
                <h2 className='text-4xl text-center'>Personaliza tu despensa</h2>
                <p className='pt-20 mx-6 pb-10 self-center text-lg text-center tracking-normal leading-5 md:pt-32'>Contactanos y te ayudamos a crear la canasta con los productos que tu quieras</p>
                <p className='mx-6 pb-10 self-center text-lg text-center tracking-normal leading-5'>Mandanos un mensaje con la siguiente información</p>
                <div className='self-center mx-5 flex flex-col justify-center'>
                    <form className='flex flex-col gap-5 pb-5'
                        ref={form}
                        onSubmit={sendEmail}
                    >
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-name'>Nombre:</label>
                            <input className='w-full' name='name' type='text' value={name} onChange={event => setName(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-mail'>Email:</label>
                            <input className='w-full' name='email' type='email' value={email} onChange={event => setEmail(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-phone'>Teléfono:</label>
                            <input className='w-full' name='phone' type='tel' value={phone} onChange={event => setPhone(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-basket'>Numero de Canastas:</label>
                            <input className='w-full' name='canastas' type='text' value={canastas} onChange={event => setCanastas(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-pres'>Presupuesto:</label>
                            <input className='w-full' name='presupuesto' type='text' value={presupuesto} onChange={event => setPresupuesto(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-msg'>Mensaje:</label>
                            <textarea className='w-full' name='message' type='text' rows="8" value={msg} onChange={event => setMsg(event.target.value)}/>
                        </div>
                        <button className='text-gitman-white bg-gitman-green h-8' type='submit'>Enviar Mesnaje</button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Custom;

export function Head() {
    return(
      <Seo />
    );
  }